import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AuthGuard from "./components/AuthGuard";
import LoadingScreen from "./components/LoadingScreen";
import DashboardLayout from "./layouts/DashboardLayout";
import Login from "./views/Login";
import LoginCallback from "./views/LoginCallback";
import { SignOutCallback } from "./views/SignOutCallback";

export function Routes() {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Route exact path="/login" name="Login Page" component={Login} />
        <Route
          exact
          path="/login-callback"
          name="Login Page"
          component={LoginCallback}
        />
        <Route
          exact
          path="/post-logout"
          name="Post Logout Page"
          component={SignOutCallback}
        />
        <Route
          path={[
            "/",
            "/my-vehicles",
            "/my-vehicles/archived",
            "/my-vehicles/create",
            "/my-vehicles/:id",
            "/market",
            "/market/:id",
          ]}
          exact
          render={() => (
            <AuthGuard>
              <DashboardLayout>
                <Suspense fallback={<LoadingScreen />}>
                  <Switch>
                    <Route
                      path="/"
                      exact
                      component={lazy(() => import("./views/Home"))}
                    />
                    <Route
                      path="/my-vehicles"
                      exact
                      component={lazy(
                        () => import("./views/MyVehicles/MyVehicles")
                      )}
                    />
                    <Route
                      path="/my-vehicles/archived"
                      exact
                      component={lazy(
                        () => import("./views/MyVehicles/ArchivedVehicles")
                      )}
                    />
                    <Route
                      path="/my-vehicles/create"
                      exact
                      component={lazy(() => import("./views/VehicleCreate"))}
                    />
                    <Route
                      path="/my-vehicles/:id"
                      exact
                      component={lazy(() => import("./views/VehicleDetails"))}
                    />
                    <Route
                      path="/market"
                      exact
                      component={lazy(() => import("./views/Market"))}
                    />
                    <Route
                      path="/market/:id"
                      exact
                      component={lazy(() => import("./views/MarketVehicle"))}
                    />
                  </Switch>
                </Suspense>
              </DashboardLayout>
            </AuthGuard>
          )}
        />
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
}
