import { createSelectorHook } from "react-redux";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk, { ThunkMiddleware } from "redux-thunk";
import { authReducer } from "./auth/reducer";
import { currencyReducer } from "./currencies/reducer";
import { notificationsReducer } from "./notifications";
import { themeReducer } from "./theme/reducers";

const reducers = combineReducers({
  auth: authReducer,
  theme: themeReducer,
  notifications: notificationsReducer,
  currencies: currencyReducer,
});

export type RootAction = Parameters<typeof reducers>[1];

export type RootState = ReturnType<typeof reducers>;

export const useTypedSelector = createSelectorHook<RootState>();

const thunkMiddlware: ThunkMiddleware<RootState, RootAction> = thunk;

const appliedMiddlware = applyMiddleware(thunkMiddlware);

export const store = createStore(
  reducers,
  composeWithDevTools(appliedMiddlware)
);

export type RootDispatch = typeof store.dispatch;
