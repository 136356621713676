import React from "react";
import { Redirect } from "react-router-dom";

export function RedirectToLogin() {
  return (
    <Redirect
      to={{
        pathname: "/login",
        state: {
          referrer: window.location.pathname,
        },
      }}
    />
  );
}
