import { CurrencyAction } from "./actions";

interface CurrencyState {
  currencies: string[];
}

const initialState: CurrencyState = {
  currencies: [],
};

export function currencyReducer(
  state = initialState,
  action: CurrencyAction
): CurrencyState {
  switch (action.type) {
    case "currencies/set-currencies":
      return { ...state, currencies: [...action.currencies].sort() };
    default:
      return state;
  }
}
