import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  logo: {
    height: 23,
    marginTop: 2,
    marginLeft: 24,
    marginRight: 10,
  },
}));

export function CollectionManagementLogo(
  props: React.ImgHTMLAttributes<HTMLImageElement>
) {
  const classes = useStyles();
  const theme = useSelector((state: RootState) => state.theme.theme);
  const logo =
    theme === "Light"
      ? "/static/logo-collection-management-black.svg"
      : "/static/logo-collection-management.svg";

  return <img alt="Logo" className={classes.logo} src={logo} {...props} />;
}
