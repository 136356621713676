import MomentUtils from "@date-io/moment";
import {
  createStyles,
  makeStyles,
  Theme,
  ThemeProvider,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { SnackbarProvider } from "notistack";
import React from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Auth from "./components/Auth";
import { Routes } from "./Routes";
import { RootState } from "./store";
import { createTheme } from "./theme";

const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      "*": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
      },
      html: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        height: "100%",
        width: "100%",
      },
      body: {
        height: "100%",
        width: "100%",
      },
      "#root": {
        height: "100%",
        width: "100%",
      },
    },
  })
);

interface AppStateProps {
  theme: Theme;
}

function App({ theme }: AppStateProps) {
  useStyles();
  if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
  }

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <SnackbarProvider>
          <BrowserRouter>
            <Auth>
              <Routes />
            </Auth>
          </BrowserRouter>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

function mapStateToProps(state: RootState): AppStateProps {
  const themeSettings = state.theme;

  const theme = createTheme(themeSettings);

  return {
    theme,
  };
}

export default connect(mapStateToProps)(App);
