import { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import UserManager from "src/services/UserManager";

interface LoginStateProps {
  referrer?: string;
}

export default function Login({
  location,
}: RouteComponentProps<
  Record<string, string>,
  Record<string, unknown>,
  LoginStateProps
>) {
  useEffect(() => {
    UserManager.signinRedirect({
      state: { referrer: location.state.referrer },
    });
  }, [location.state?.referrer]);

  return null;
}
