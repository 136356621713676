import { RootDispatch } from "..";

function getMockNotifications(): Notification[] {
  return [
    {
      id: "5e8883f1b51cc1956a5a1ec0",
      title: "MOT expiry",
      description: "3 need your attention",
      type: "order_placed",
      createdAt: Date.now() - 2 * 60 * 60 * 1000,
    },
    {
      id: "5e8883f7ed1486d665d8be1e",
      title: "New message received",
      description: "You have 2 unread messages",
      type: "new_message",
      createdAt: Date.now() - 24 * 60 * 60 * 1000,
    },
    {
      id: "5e8883fca0e8612044248ecf",
      title: "Service quotes",
      description: "4 invites received",
      type: "item_shipped",
      createdAt: Date.now() - 3 * 24 * 60 * 60 * 1000,
    },
  ];
}

interface SetNotifications {
  type: "@notificatons/set-notifications";
  notifications: Notification[];
}

export function setNotifications(
  notifications: Notification[]
): SetNotifications {
  return {
    type: "@notificatons/set-notifications",
    notifications,
  };
}

export function getNotifications() {
  return function (dispatch: RootDispatch) {
    const notifications = getMockNotifications();
    const action = setNotifications(notifications);
    dispatch(action);
  };
}

interface Notification {
  id: string;
  title: string;
  description: string;
  type: string;
  createdAt: number;
}

interface NotificationsState {
  notifications: Notification[];
}

const defaultState: NotificationsState = {
  notifications: [],
};

export function notificationsReducer(
  state = defaultState,
  action: SetNotifications
): NotificationsState {
  switch (action.type) {
    case "@notificatons/set-notifications":
      return { ...state, notifications: action.notifications };
    default:
      return state;
  }
}
