import {
  colors,
  createMuiTheme,
  PaletteType,
  responsiveFontSizes,
  Theme,
  ThemeOptions,
} from "@material-ui/core";
import { ThemeState } from "src/store/theme/reducers";
import { softShadows, strongShadows } from "./shadows";
import typography from "./typography";
import { ThemeName } from "src/store/theme/actions";

const Light: PaletteType = "light";
const Dark: PaletteType = "dark";

const baseConfig: ThemeOptions = {
  direction: "ltr",
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.075)",
      },
    },
  },
};

const lightTheme = {
  name: "Light",
  overrides: {
    MuiInputBase: {
      input: {
        "&::placeholder": {
          opacity: 1,
          color: colors.blueGrey[600],
        },
      },
    },
  },
  palette: {
    type: Light,
    action: {
      active: colors.blueGrey[600],
    },
    background: {
      default: colors.common.white,
      dark: "#f4f6f8",
      paper: colors.common.white,
    },
    primary: {
      main: colors.indigo[600],
    },
    secondary: {
      main: "#5850EC",
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
  },
  shadows: softShadows,
};

const darkTheme = {
  name: "Dark",
  palette: {
    type: Dark,
    action: {
      active: "rgba(255, 255, 255, 0.54)",
      hover: "rgba(255, 255, 255, 0.04)",
      selected: "rgba(255, 255, 255, 0.08)",
      disabled: "rgba(255, 255, 255, 0.26)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
      focus: "rgba(255, 255, 255, 0.12)",
    },
    background: {
      default: "#282C34",
      dark: "#1c2025",
      paper: "#282C34",
    },
    primary: {
      main: "#8a85ff",
    },
    secondary: {
      main: "#8a85ff",
    },
    text: {
      primary: "#e6e5e8",
      secondary: "#adb0bb",
    },
  },
  shadows: strongShadows,
};

const unicornTheme = {
  name: "Unicorn",
  palette: {
    type: Dark,
    action: {
      active: "rgba(255, 255, 255, 0.54)",
      hover: "rgba(255, 255, 255, 0.04)",
      selected: "rgba(255, 255, 255, 0.08)",
      disabled: "rgba(255, 255, 255, 0.26)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
      focus: "rgba(255, 255, 255, 0.12)",
    },
    background: {
      default: "#2a2d3d",
      dark: "#222431",
      paper: "#2a2d3d",
    },
    primary: {
      main: "#a67dff",
    },
    secondary: {
      main: "#a67dff",
    },
    text: {
      primary: "#f6f5f8",
      secondary: "#9699a4",
    },
  },
  shadows: strongShadows,
};

function getTheme(name: ThemeName) {
  switch (name) {
    case "Dark":
    default:
      return darkTheme;
    case "Light":
      return lightTheme;
    case "Unicorn":
      return unicornTheme;
  }
}

export function createTheme(settings: ThemeState): Theme {
  const themeConfig = getTheme(settings.theme);

  const themeOptions = {
    ...baseConfig,
    ...themeConfig,
    direction: settings.direction,
  };

  let theme = createMuiTheme(themeOptions);

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
