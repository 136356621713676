import axios from "src/utils/axios";

export function configureAxiosInterceptors(onLogOut: () => void) {
  axios.interceptors.response.use(undefined, (error) => {
    if (error.response && error.response.status === 401) {
      onLogOut();
    }

    return Promise.reject(error);
  });
}
