import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import UserManager from "src/services/UserManager";
import { RootState } from "src/store";

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
}));

interface AccountStateProps {
  userName?: string;
}

type AccountProps = AccountStateProps;

function Account({ userName }: AccountProps) {
  const classes = useStyles();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    UserManager.signoutRedirect();
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        {...{ ref }}
      >
        <Avatar className={classes.avatar}>
          {userName?.substr(0, 1).toUpperCase()}
        </Avatar>
        <Hidden smDown>
          <Typography variant="h6" color="inherit">
            {userName}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
}

function mapStateToProps(state: RootState): AccountStateProps {
  return {
    userName: state.auth.user?.profile?.preferred_username,
  };
}

export default connect(mapStateToProps)(Account);
