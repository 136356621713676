import { User } from "oidc-client";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import UserManager from "src/services/UserManager";
import { RootDispatch, RootState } from "src/store";
import { userLoggedIn } from "src/store/auth/actions";

interface StateProps {
  isLoggedIn: boolean;
  loginRedirect?: string;
}

interface DispatchProps {
  onLoggedIn: (user: User) => void;
}

type LoginProps = StateProps & DispatchProps;

function LoginCallback({ isLoggedIn, loginRedirect, onLoggedIn }: LoginProps) {
  useEffect(() => {
    (async () => {
      const user = await UserManager.signinRedirectCallback();
      onLoggedIn(user);
    })();
  }, [onLoggedIn]);

  if (isLoggedIn) {
    return <Redirect to={loginRedirect ?? "/"} />;
  }

  return null;
}

function mapStateToProps(state: RootState): StateProps {
  return {
    isLoggedIn: state.auth.user !== undefined,
    loginRedirect: state.auth.user?.state?.referrer,
  };
}

function mapDispatchToProps(dispatch: RootDispatch): DispatchProps {
  return {
    onLoggedIn: (user: User) => dispatch(userLoggedIn(user)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginCallback);
