import { makeStyles } from "@material-ui/core";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { connect } from "react-redux";
import { loadCurrencies } from "src/store/currencies/thunks";
import { AppTheme } from "src/theme/types";
import NavBar from "./NavBar";
import TopBar from "./TopBar";

const useStyles = makeStyles<AppTheme>((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 16,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  },
}));

interface DefaultLayoutProps {
  loadCurrencies: () => void;
}

function DashboardLayout({
  children,
  loadCurrencies,
}: PropsWithChildren<DefaultLayoutProps>) {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  useEffect(() => {
    loadCurrencies();
  }, [loadCurrencies]);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
}

export default connect(null, { loadCurrencies })(DashboardLayout);
