interface SetCurrencies {
  type: "currencies/set-currencies";
  currencies: string[];
}

export type CurrencyAction = SetCurrencies;

export function setCurrencies(currencies: string[]): SetCurrencies {
  return {
    type: "currencies/set-currencies",
    currencies,
  };
}
