import { getCurrencies } from "src/services/CurrencyService";
import { RootDispatch } from "..";
import { setCurrencies } from "./actions";

export function loadCurrencies() {
  return async function (dispatch: RootDispatch) {
    const currencies = await getCurrencies();
    dispatch(setCurrencies(currencies));
  };
}
