import { TextDirection, ThemeAction, ThemeName } from "./actions";

export interface ThemeState {
  responsiveFontSizes: boolean;
  theme: ThemeName;
  direction: TextDirection;
}

const defaultSettings: ThemeState = {
  direction: "ltr",
  responsiveFontSizes: true,
  theme: "Dark",
};

export function themeReducer(
  state = defaultSettings,
  action: ThemeAction
): ThemeState {
  switch (action.type) {
    case "themes/set-theme-name":
      return { ...state, theme: action.name };
    default:
      return state;
  }
}
