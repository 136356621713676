import axios from "axios";
import UserManager from "src/services/UserManager";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const user = await UserManager.getUser();
  if (user) {
    config.headers.Authorization = `Bearer ${user.access_token}`;
  }

  return config;
});

export default instance;
