import { User } from "oidc-client";
import { AuthAction } from "./actions";

interface AuthState {
  user?: User;
}

export function authReducer(
  state: AuthState = {},
  action: AuthAction
): AuthState {
  switch (action.type) {
    case "USER/LOGGED_IN":
      return { ...state, user: action.user };
    case "USER/SIGNED_OUT":
      return { ...state, user: undefined };
    default:
      return state;
  }
}
