import { User } from "oidc-client";
import React, { PropsWithChildren } from "react";
import { connect } from "react-redux";
import { RootState } from "src/store";
import { RedirectToLogin } from "./RedirectToLogin";

interface AuthGuardStateProps {
  user?: User;
}

type AuthGuardProps = PropsWithChildren<AuthGuardStateProps>;

function AuthGuard({ children, user }: AuthGuardProps) {
  if (!user) {
    return <RedirectToLogin />;
  }

  return <>{children}</>;
}

function mapStateToProps(state: RootState): AuthGuardStateProps {
  return {
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(AuthGuard);
