import React, { PropsWithChildren, useEffect } from "react";
import { connect } from "react-redux";
import { configureAxiosInterceptors } from "src/services/authService";
import { RootDispatch } from "src/store";
import { userSignedOut } from "src/store/auth/actions";

interface AuthDispatchProps {
  onLogOut: () => void;
}

type AuthProps = PropsWithChildren<AuthDispatchProps>;

function Auth({ onLogOut, children }: AuthProps) {
  useEffect(() => {
    configureAxiosInterceptors(onLogOut);
  }, [onLogOut]);

  return <>{children}</>;
}

function mapDispatchToProps(dispatch: RootDispatch): AuthDispatchProps {
  return {
    onLogOut: () => dispatch(userSignedOut()),
  };
}

export default connect(null, mapDispatchToProps)(Auth);
