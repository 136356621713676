import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import UserManager from "src/services/UserManager";

export class SignOutCallback extends Component {
  state = {
    signedOut: false,
  };

  componentDidMount() {
    UserManager.signoutRedirectCallback().then(() => {
      this.setState({
        signedOut: true,
      });
    });
  }

  render() {
    if (this.state.signedOut) {
      return <Redirect to="/" />;
    }

    return null;
  }
}
