/* eslint-disable camelcase */
import { UserManager } from "oidc-client";

export default new UserManager({
  authority: "https://login.collection.management",
  client_id: "collectionmanagement-ui-client",
  redirect_uri: `${window.location.origin}/login-callback`,
  response_type: "code",
  scope: "openid profile stockapi roles offline_access",
  post_logout_redirect_uri: `${window.location.origin}/post-logout`,
  automaticSilentRenew: true,
});
