/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { User } from "oidc-client";
import React, { useEffect } from "react";
import {
  Folder as FolderIcon,
  Home as HomeIcon,
  Layers as LayersIcon,
  PlusCircle as PlusCircleIcon,
} from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import Logo from "src/components/Logo";
import { RootState } from "src/store";
import NavItem from "./NavItem";

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
}));

interface NavBarStateProps {
  user?: User;
}

interface NavBarProps extends NavBarStateProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

function NavBar({ openMobile, onMobileClose, user }: NavBarProps) {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  if (!user) {
    return null;
  }

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <Avatar>
              {user.profile.preferred_username?.substr(0, 1).toUpperCase()}
            </Avatar>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.profile.preferred_username}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {user.profile.birthdate}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          <List>
            <List disablePadding>
              <NavItem depth={0} icon={HomeIcon} href="/" title="Home" />
            </List>
            <List
              disablePadding
              subheader={
                <ListSubheader disableGutters disableSticky>
                  Your Collection
                </ListSubheader>
              }
            >
              <NavItem
                title="My Vehicles"
                href="/my-vehicles"
                icon={FolderIcon}
                depth={0}
              />
              <NavItem
                title="Archived Vehicles"
                href="/my-vehicles/archived"
                icon={LayersIcon}
                depth={0}
              />
              <NavItem
                title="Add Vehicle"
                href="/my-vehicles/create"
                icon={PlusCircleIcon}
                depth={0}
              />
            </List>
            <List
              disablePadding
              subheader={
                <ListSubheader disableGutters disableSticky>
                  Market
                </ListSubheader>
              }
            >
              <NavItem title="Market" href="/market" depth={0} />
            </List>
          </List>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

function mapStateToProps(state: RootState): NavBarStateProps {
  return {
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(NavBar);
