export type ThemeName = "Light" | "Dark" | "Unicorn";

export type TextDirection = "ltr" | "rtl";

interface SetThemeName {
  type: "themes/set-theme-name";
  name: ThemeName;
}

export type ThemeAction = SetThemeName;

export function setThemeName(name: ThemeName): SetThemeName {
  return {
    type: "themes/set-theme-name",
    name,
  };
}
