import { User } from "oidc-client";

export interface UserLoggedIn {
  type: "USER/LOGGED_IN";
  user: User;
}

export interface UserSignedOut {
  type: "USER/SIGNED_OUT";
}

export type AuthAction = UserLoggedIn | UserSignedOut;

export function userLoggedIn(user: User): UserLoggedIn {
  return {
    type: "USER/LOGGED_IN",
    user: user,
  };
}

export function userSignedOut(): UserSignedOut {
  return {
    type: "USER/SIGNED_OUT",
  };
}
