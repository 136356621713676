import { FormControlLabel, Switch } from "@material-ui/core";
import React from "react";
import { connect, MapDispatchToPropsParam } from "react-redux";
import { RootState } from "src/store";
import { setThemeName, ThemeName } from "src/store/theme/actions";

interface DarkLightStateProps {
  theme: ThemeName;
}

interface DarkLightDispatchProps {
  setTheme: (theme: ThemeName) => void;
}

type DarkLightProps = DarkLightStateProps & DarkLightDispatchProps;

function DarkLightMode({ setTheme, theme }: DarkLightProps) {
  function handleThemeToggle(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    setTheme(checked ? "Dark" : "Light");
  }

  const labelText = theme + " mode";

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={theme === "Dark"}
            edge="start"
            name="direction"
            onChange={handleThemeToggle}
          />
        }
        label={labelText}
      />
    </>
  );
}

function mapStateToProps(state: RootState): DarkLightStateProps {
  return {
    theme: state.theme.theme,
  };
}

const dispatchObj: MapDispatchToPropsParam<
  DarkLightDispatchProps,
  Record<string, unknown>
> = {
  setTheme: setThemeName,
};

export default connect(mapStateToProps, dispatchObj)(DarkLightMode);
