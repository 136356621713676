import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  makeStyles,
  SvgIcon,
  Toolbar,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { Menu as MenuIcon } from "react-feather";
import { Link as RouterLink } from "react-router-dom";
import { CollectionManagementLogo } from "src/components/CollectionManagementLogo";
import Logo from "src/components/Logo";
import { AppTheme } from "src/theme/types";
import Account from "./Account";
import DarkLightMode from "./DarkLightMode";

const useStyles = makeStyles<AppTheme>((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === "Light"
      ? {
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
        }
      : {}),
    ...(theme.name === "Dark"
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),
  },
  toolbar: {
    minHeight: 64,
  },
  logoflex: {
    display: "flex",
    alignItems: "inherit",
  },
}));

interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
}

function TopBar({ className, onMobileNavOpen, ...rest }: TopBarProps) {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/" className={classes.logoflex}>
            <Logo />
            <CollectionManagementLogo />
          </RouterLink>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        <DarkLightMode />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
